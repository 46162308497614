<template>
<div class="display-block">
    <v-card>
        <v-card-text>
            <h3>Package Details :</h3>
            <v-flex>
                <row-item label="Name" :value="packageItem.name"></row-item>
                <row-item label="Buy Type" :value="packageItem.bType=='ONE_TIME'?'One Time':'Future Buy'"></row-item>
                <row-item label="Required Items"></row-item>
                <v-layout v-if="packageItem.products?.length>0">
                    <v-flex sm2 v-for="product in packageItem.products" :key="product._id">
                        <v-chip>{{ product.title }}</v-chip>
                    </v-flex>
                </v-layout>
                <row-item v-if="packageItem.addOns?.length>0" label="Add On Items"></row-item>
                <v-layout v-if="packageItem.addOns?.length>0">
                    <v-flex sm2 v-for="product in packageItem.addOns" :key="product._id">
                        <v-chip>{{ product.title }}</v-chip>
                    </v-flex>
                </v-layout>
                <row-item label="Price" :value="packageItem.sellingPrice"></row-item>
                <v-layout wrap>
                    <v-flex v-if="packageItem.image" xs12 sm4 md3 class="pa-1" width="200">
                        <video-card :src="packageItem.image?.path" v-if="packageItem.image.path&&packageItem.image.mimetype.startsWith('video/')"></video-card>
                        <image-card :src="packageItem.image?.path" v-else-if="packageItem.image.path&&packageItem.image.mimetype.startsWith('image/')"></image-card>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-card-text>
    </v-card>
    <v-flex text-right>
        <v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn>
    </v-flex>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
export default {
    components: {
    },
    data() {
        return {
            packageItem: {
                name: '',
                desc: '',
                bType: "ONE_TIME",
                products: [],
                addOns: [],
                sellingPrice: 0,
                vendorId: '',
                image:''
            },
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.packageItem = (await this.getItem(appConstants.PACKAGES_API + "/" + this.id)).data
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
